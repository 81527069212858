<template>
  <div class="home_top">
    <div class="inner inner1">
      <div class="left_item">
        <h1>Welcome to <span>KK Cash</span> </h1>
        <h1>Your trusted financial</h1>
        <h1>partner in Nigeria</h1>
        <!-- <h4>Trusted by 70+ lakh customers worldwide, the KK Cash online</h4>
        <h4>loan app offers fast growing instant personal loans up to ₦500,000.</h4>
        <h4>No salary slip, bank statement or credit score needed.</h4> -->
        <div class="item_download" @click="downloadApp()">
          <img src="@/assets/images/loanImg/Google-play.png" alt="">
        </div>
      </div>
    </div>
    <div class="inner inner2">
      <div class="left_item">
        <h1>Why choose KK Cash? </h1>
        <!-- <div>
          <img src="@/assets/images/loanImg/logo_icon_2.png" alt="">
          <p>KK Cash</p>
        </div> -->
      </div>
      <div class="right_item">
        <div class="item_info">
          <div class="info_tips">
            <img class="info_img1" src="@/assets/images/loanImg/app-icon-1.png" alt="">
            <!-- <h4>Up to ₦10,000 direct to</h4> -->
            <p>Fast and Easy Application Process: Applying for a </p>
            <p>loan with KK Cash is as simple as 1-2-3. Just</p>
            <p>download our app, fill in your details, and submit</p>
            <p>your application. Our team will review it quickly, </p>
            <p>and you’ll receive a decision within minutes.</p>
          </div>
          <div class="info_tips">
            <img class="info_img1" src="@/assets/images/loanImg/app-icon-2.png" alt="">
            <!-- <h4>90+ days to repay. No salary</h4> -->
            <p>Convenient Repayment Options: We believe in </p>
            <p>providing flexible repayment options to make it </p>
            <p>easier for you to manage your loan. Choose from </p>
            <p>weekly, bi-weekly, or monthly installments, and </p>
            <p>repay your loan at your own pace.</p>
          </div>
          <div class="info_tips">
            <img class="info_img1" src="@/assets/images/loanImg/app-icon-3.png" alt="">
            <!-- <h4>Always private</h4> -->
            <p>No Hidden Fees: We believe in transparency,</p>
            <p>which is why we have no hidden fees or</p>
            <p>penalties. You’ll know exactly how much you</p>
            <p>need to repay from day one, ensuring peace of</p>
            <p>mind throughout your loan journey.</p>
          </div>
          <div class="info_tips">
            <img class="info_img1" src="@/assets/images/loanImg/app-icon-4.png" alt="">
            <!-- <h4>Grow your</h4> -->
            <p>Secure and Confidential: Your privacy and</p>
            <p>security are of utmost importance to us. We use </p>
            <p> advanced encryption technology to protect your </p>
            <p> personal and financial information, giving you the </p>
            <p>confidence to use our app worry-free.</p>
          </div>
          <div class="info_tips">
            <img class="info_img1" src="@/assets/images/loanImg/app-icon-5.png" alt="">
            <!-- <h4>Grow your</h4> -->
            <p>Competitive Interest Rates: We understand that </p>
            <p>interest rates make a big difference when it </p>
            <p>comes to borrowing money. That’s why we offer </p>
            <p> competitive rates, ensuring that you get the best</p>
            <p> deal possible.</p>
          </div>
          <div class="info_tips">
            <img class="info_img1" src="@/assets/images/loanImg/app-icon-6.png" alt="">
            <!-- <h4>Grow your</h4> -->
            <p>Excellent Customer Support: Our dedicated</p>
            <p>customer support team is here to assist you</p>
            <p>every step of the way. If you have any questions</p>
            <p>or need assistance, feel free to reach out to us</p>
            <p>via phone, email, or live chat.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="inner inner3">
      <div class="inner3_item">
        <!-- <h1>About the KK Cash loan app</h1> -->
        <p>Our simple and secure lending platform allows you to apply for a loan within minutes, without the hassle of lengthy paperwork or credit checks. Whether you need funds for medical emergencies, education, business expansion, or any other personal needs, KK Cash is here to make it happen.</p>
      </div>
      <div class="inner3_item">
        <!-- <h2>Your Information Is Secure</h2> -->
        <p>Disclaimer: KK Cash is a licensed and regulated financial institution. Loans are subject to approval and terms and conditions apply. Please borrow responsibly.</p>
      </div>
    </div>
    <div class="inner inner4">
      <h3>What our customers say</h3>
      <!-- <p>Tenemos 4.7 de 5 estrellas en Google Play y más de 100 k de reseñas de 5 estrellas en todo el mundo.</p> -->
      <!-- <p><img src="@/assets/images/loanImg/star2.png">4.5</p> -->
      <div class="flex jc-c">
        <div class="carousel-container">
          <div class="carousel">
            <transition-group name="fade" tag="div" class="carousel-wrapper">
              <div v-for="(item) in visibleItems" :key="item.id" class="carousel-item flex fd-c">
                <img class="tx" :src="item.img">
                <div>
                  <h1>{{ item.name }}</h1>
                  <!-- <h2>{{ item.txt }}</h2> -->
                  <!-- <h3>{{ item.name2 }}</h3> -->
                  <p>{{ item.txt2 }}</p>
                </div>
              </div>
            </transition-group>
          </div>
        </div>
      </div>
      <!-- <div class="flex jc-c">
        <button class="carousel-button" :disabled="currentIndex === 0" @click="previousSlide"><img src="@/assets/images/loanImg/black_left.png"></button>
        <button class="carousel-button" :disabled="currentIndex >= items.length - 3" @click="nextSlide"><img src="@/assets/images/loanImg/black_right.png"></button>
      </div> -->
    </div>
    <div class="inner inner5">
      <h3>Company Introduction</h3>
      <div class="flex jc-c">
        <img class="img_high_rises" src="@/assets/images/img_high_rises.png">
        <div class="div_info">
          <div class="tv_big">
            <span>
              Hollandale Nigeria Limited
            </span>
            is an emerging fintech company focused on providing innovative financial services.
          </div>
          <div class="tv_small">
            We are dedicated to leveraging advanced technology to enhance user experience and streamline the loan application and management processes, meeting the needs of customers in a rapidly changing economic environment.
            <br>
            <br>
            Through technology-driven solutions, Hollandale Nigeria Limited aims to achieve inclusive finance, enabling everyone to easily access the financial support they need. We believe that financial services should be transparent, convenient, and capable of helping users achieve their financial goals.
          </div>
        </div>
      </div>
    </div>
    <div class="inner inner6">
      <h3>Core Services & Our Advantages</h3>
      <div class="tv_tip">
        At Hollandale Nigeria Limited, we believe in the power of technology to transform the future of financial services. Our mission is to offer better services through innovative financial solutions, helping our customers succeed financially.
      </div>
      <div class="tv_desc div_top_bottom">
        <div class="tv_title">
          Microloans
        </div>
        Offering flexible microloan options to meet the funding needs of individuals and small businesses.
      </div>
      <div class="div_desc">
        <div class="div_grid">
          <div class="tv_desc div_left">
            <div class="tv_title">
              Credit Scoring
            </div>
            Providing real-time credit scoring services through big data analysis to help users better manage their finances.
          </div>
          <div class="tv_desc div_left">
            <div class="tv_title">
              Smart Risk Assessment
            </div>
            Utilizing machine learning algorithms for intelligent assessment of loan applications, improving lending efficiency and reducing default risk
          </div>
        </div>
        <img class="img_tag_desc" src="@/assets/images/img_tag_desc.png">
        <div class="div_grid">
          <div class="tv_desc div_right">
            <div class="tv_title">
              Security
            </div>
            We employ advanced security technologies to ensure the safety of user data and transactions.
          </div>
          <div class="tv_desc div_right">
            <div class="tv_title">
              Personalized Services
            </div>
            Providing customized loan solutions based on users’ financial situations and needs.
          </div>
        </div>
      </div>
      <div class="tv_desc div_top_bottom">
        <div class="tv_title">
          User-Friendly Platform
        </div>
        We have an intuitive user interface that makes the loan application and management process simple and quick.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        // 您的轮播内容数据
        { id: '1', name: 'Fatima', name2: '¡Fue increíble!', txt: 'Profesor', txt2: '“KK Cash is a lifesaver! I needed some \n extra cash for an emergency and their \n app made it so easy to apply for a loan. \n The process was quick and the money \n was in my account within a few hours. \n Highly recommend!”', img: require('@/assets/images/Product01.png') },
        { id: '2', name: 'Amina', name2: 'Seguro y rápido', txt: 'Contable', txt2: '“I’ve tried a few different loan apps in \n Nigeria, but KK Cash is by far the \n best one. The app is user-friendly and \n the loan terms are reasonable. Plus, \n the customer service team is very \n helpful and responsive. Great app!”', img: require('@/assets/images/Product02.png') },
        { id: '3', name: 'Ibrahim', name2: '¡Muchas gracias!', txt: 'Conductor', txt2: '“KK Cash is my go-to app whenever I \n need a quick loan. The application \n process is straightforward and they \n offer flexible repayment options. The \n interest rates are also competitive \n compared to other apps I’ve used. \n Definitely recommend trying it out!”', img: require('@/assets/images/Product03.png') },
        { id: '4', name: 'Abdullahi', name2: 'Gran producto', txt: 'Líder de línea', txt2: '“I’ve been using KK Cash for a \n while now and I have to say, it’s \n been a great experience. The app \n is easy to navigate and they have \n excellent customer service. I also \n like how they offer higher loan \n amounts for repeat borrowers. \n Keep up the good work, KK Cash!”', img: require('@/assets/images/Product04.png') }
      ],
      visibleItemCount: 4,
      currentIndex: 0
    }
  },
  computed: {
    visibleItems() {
      return this.items.slice(this.currentIndex, this.currentIndex + this.visibleItemCount)
    }
  },
  methods: {
    downloadApp() {
      window.open('https://play.google.com/store/apps/details?id=kk.cash.loan.credit')
    },
    previousSlide() {
      if (this.currentIndex > 0) {
        this.currentIndex--
      }
    },
    nextSlide() {
      if (this.currentIndex < this.items.length - this.visibleItemCount) {
        this.currentIndex++
      } else {
        this.currentIndex--
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .home_top{
    min-width: 1200px;
    background: #fff;
    .inner{
      width: 100%;
      padding:50px calc((100% - 1100px) / 2) 50px;
      margin: 0 auto;
    }
    .bottom-20{
      margin-bottom: 20px;
    }
    .inner1{
      // background: linear-gradient(258deg, #43C5D6 0%, #4484EC 100%);
      background: url("~@/assets/images/loanImg/inner1_bg.png") no-repeat;
      background-size: auto 100%;
      background-position-x: center;
      height: 38rem;
      display: flex;
      align-items: center;
      padding:0px calc((100% - 1200px) / 2) 0;
      .left_item{
        float: left;
        width: 650px;
        h1{
          font-size: 55px;
          // color: white;
          font-weight: 700;
          margin-bottom: 20px;
          line-height: 70px;
        }
        h4{
          padding-right: 17.5px;
          font-size: 16px;
          line-height: 26px;
          // color: #FFFFFF99;
          font-weight: normal;
          // margin-bottom: 10px;
        }
        // span{
        //   color: #FF5833;
        // }
        .item_progress{
          width: 230px;
          p{
            width: 90px;
            text-align: center;
            line-height: 30px;
            border-radius: 5px;
            color: white;
            font-size: 12px;
            font-weight: bold;
            margin: 0 auto 10px;
            background: #4488EB;
          }
          img{
            width: 245px;
            height: 15px;
          }
        }
        .item_info{
          margin: 20px 0;
          display: flex;
          height: 100px;
          .info_img1{
            width: 15px;
          }
          .info_tips{
            display: flex;
            flex-direction: column;
            p{
              flex: 1;
              display: flex;
              box-sizing: border-box;
              // margin-left: 10px;
              font-size: 12px;
              color: #666666;
              align-items: center;
              img{
                margin-right: 10px;
              }
            }
          }
          .info_img2{
            width: 135px;
            height: 135px;
          }
        }
        .item_download{
          display: flex;
          margin-top: 40px;
          width: 200px;
          // background: black;
          color: white;
          border-radius: 5px;
          align-items: center;
          justify-content: center;
          img{
            width: 200px;
            height: 60px;
            margin-right: 5px;
          }
        }
      }
      .right_item{
        float: right;
        // margin-right: 50px;
        img{
          width: 550px;
        }
      }
    }
    .inner2{
      // background:#F8F9FF;
      display: flex;
      flex-direction: column;
      // height: 600px;
      padding-top: 100px;
      .left_item{
        flex: 1;
        // background: url("~@/assets/images/loanImg/inner2_bg.png") no-repeat;
        // background-size: 100% auto;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 80px;
        h1{
          text-align: center;
          // width: 370px;
          font-weight: bold;
          line-height: 40px;
          font-size: 35px;
        }
        div{
          position: absolute;
          right: 20px;
          top: calc(50% - 40px);
          img{
            display: block;
            width: 60px;
            margin: 0 auto 15px;
          }
          p{
            font-weight: bold;
            font-size: 16px;
          }
        }
      }
      .right_item{
        flex: 1.3;
        .item_info{
          display: flex;
          background: white;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-bottom: 30px;
          .info_img1{
            width: 70px;
            height: 70px;
            position: absolute;
            top: -35px;
          }
          .info_tips{
            position: relative;
            display: flex;
            flex-direction: column;
            background: #fff;
            // margin-right: 5px;
            // flex: 1;
            width: 30%;
            align-items: center;
            justify-content: center;
            padding: 50px 10px 65px;
            margin-bottom: 70px;
            border: 1px solid #E2E2E2;
            border-bottom: 7px solid #FF6E51;
            h4{
              line-height: 20px;
              font-weight: normal;
            }
            p{
              box-sizing: border-box;
              font-size: 12px;
              line-height: 18px;
              font-weight: normal;
              img{
                margin-right: 10px;
              }
            }
            & h4:nth-last-child(1){
              margin-bottom:20px;
            }
          }
          & .info_tips:nth-last-child(1){
            margin-right: 0px;
          }
        }
      }
    }
    .inner3 {
      background: url("~@/assets/images/loanImg/inner3_bg.png") no-repeat left;
      // background-color: #EF664B;
      background-size: auto 100%;
      background-position-x: center;
      height: 600px;
      padding: 180px calc((100% - 1200px) / 2) 50px calc((100% - 50px)/ 2);
      h3 {
        font-size: 35px;
        margin:50px 0;
      }
      h1,h2{
        text-align: center;
        margin-bottom: 30px;
      }
      .inner3_item{
        width: 100%;
        // margin: 60px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 35px;
        opacity: 0.9;
        p{
          line-height: 25px;
          font-size: 16px;
          color: white;
        }
      }
    }
    .inner4 {
      background: #FFF;
      padding: 50px calc((100% - 1250px) / 2) 60px;
      h3{
        font-size: 30px;
        text-align: center;
        position: relative;
        margin-bottom: 30px;
      }
      p{
        font-size: 14px;
        color: #666666;
        text-align: center;
        line-height: 28px;
        margin-bottom: 40px;
        img{
          width: 180px;
          height: 25px;
          vertical-align:top !important;
          margin-right: 15px;
        }
      }
      .carousel-container {
        position: relative;
        width: 100%;
        height: 350px;
      }
      .carousel {
        display: flex;
        overflow: hidden;
        height: 100%;
      }
      .carousel-wrapper {
        display: flex;
        width: 100%;
        justify-content:space-around;
        align-items: center;
        padding: 20px 0 0 0;
      }
      .carousel-item {
        // flex: 1;
        height: 280px;
        display: flex;
        align-items: center;
        background:#F3F3F3;
        border-radius: 20px;
        font-size: 18px;
        padding: 0px 15px 10px;
        margin: 0 8px;
        .stars{
          img {
            width: 14px;
            height: 14px;
          }
        }
        h1{
          color: black;
          font-size: 15px;
          line-height: 18px;
          text-align:  center;
          font-weight: bold;
          margin: 25px 0;
        }
        h2{
          color: #999999;
          font-size: 12px;
          line-height: 30px;
          text-align:  center;
          font-weight: 500;
        }
        h3{
          color: black;
          font-size: 15px;
          line-height: 18px;
          text-align:  center;
          font-weight: bold;
          margin-top: 15px;
          margin-bottom: 10px;
        }
        img{
          width: 70px;
          height: 70px;
          margin-top: -30px;
        }
        p{
          color: #666666;
          height: 100px;
          font-size: 12px;
          text-align: left;
          font-weight: normal;
          line-height: 20px;
          margin-bottom: 0;
        }
      }
      .carousel-button {
        // position: absolute;
        // padding: 20px;
        font-size: 20px;
        border: none;
        background-color: transparent;
        color: #555;
        outline: none;
        margin: 10px 5px;
        cursor: pointer;
        img {
          width: 30px;
        }
      }
      .fade-enter-active,
      .fade-leave-active {
        transition: opacity 0.2s, transform 0.2s;
      }

      .fade-enter,
      .fade-leave-to {
        opacity: 0;
        transform: translateX(200px);
      }
    }
    .inner5 {
      h3{
        font-size: 30px;
        text-align: center;
        position: relative;
        margin-bottom: 30px;
      }
      .img_high_rises {
        width: 260px;
        height: 285px;
        margin-right: 64.5px;
      }
      .div_info {
        display: grid;
        width: 380px;
        text-align: left;
        .tv_small {
          color: #666666;
          font-size: 12px;
          line-height: 16px;
          margin-top: 35px;

        }
        .tv_big {
          color: #333333;
          font-size: 24px;
          line-height: 32px;
          font-weight: bold;
          span {
            color: #FF6E51;
          }
        }
      }
    }
    .inner6 {
      background: #F3F3F3;
      align-items: center;
      text-align: center;
      padding: 30px 0 60px 0;
       h3 {
        font-size: 30px;
        text-align: center;
        position: relative;
        margin-bottom: 10px;
      }
      .tv_tip {
        text-align: center;
        color: #666666;
        font-size: 12px;
        line-height: 16px;
        max-width: 480px;
        margin: 0 auto 34.5px;
      }
      .tv_desc {
        line-height: 20px;
        color: #666666;
        font-size: 12px;
         &.div_top_bottom {
          max-width: 400px;
          margin: 0 auto;
        }
        &.div_left {
          padding-top: 55px;
          height: 150px;
          max-width: 400px;
          text-align: right;
        }
        &.div_right {
          padding-top: 64px;
          height: 150px;
          text-align: left;
          max-width: 400px;
        }
        .tv_title {
          margin-bottom: 4px;
          color: #FF6E51;
          font-size: 14px;
          line-height: 18px;
          font-weight: bold;
        }
      }
      .div_desc {
        display: flex;
        justify-items: center;
        justify-content: center;
        margin: 9px 12px 9px;
        .div_grid {
          display: grid;
          // margin: 0 auto;
        }

      }
      .img_tag_desc {
        margin: 0 12px 0;
        width: 274.22px;
        height: 297px;
        vertical-align: middle;
      }
    }
  }
@media only screen and (max-width: 720px){
  .home_top{
    min-width: calc(100vw) !important;
    .inner{
      min-width: calc(100vw) !important;
      display: flex;
      flex-wrap: wrap;
    }
    .inner1{
      height: 220px;
      position: relative;
      padding: 0 20px;
      h1{
        font-size: 18px !important;
        line-height: 25px !important;
        margin-bottom: 0 !important;
      }
      .left_item{
        margin: 25px 0;
      }
      .right_item{
        width: calc(100vw);
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 350px !important;
          position: absolute;
          bottom: 0;
        }
      }
    }
    .inner2{
      padding: 40px 20px 0;
      height: 1900px;
      // display: flex;
      // flex-direction: column;
      .left_item{
        flex: 0;
        margin-bottom: 40px;
        h1{
          font-size: 20px ;
        }
      }
      // }
      .right_item{
        width: calc(100vw - 40px);
        flex: 0;
        p{
          font-size: 18px;
        }
        .item_info{
          // flex-direction: column;
        }
        .info_tips{
          width: 100% !important;
        }
      }
    }
    .inner3{
      width: calc(100vw - 40px) !important;
      margin: 0 auto  0px !important;
      padding: 0;
      height: 600px;
      background: #FF6E51;
      h3 {
        font-size: 18px;
        padding: 0 20px;
      }
      img {
        width: 90%;
        margin: 0 auto;
      }
      .inner3_item {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0;
        .content {
          margin: 20px 0;
        }
      }
    }
    .inner4 {
      padding: 30px 20px 30px;
      width: calc(100vw);
      position: relative;
      display: grid !important;
      // display: none;
      h3 {
        text-align: center;
        margin-bottom: 30px;
        line-height: 30px;
      }
      .carousel-container{
        height: auto;
      }
      .carousel-wrapper {
        display: flex;
        width: 100%;
        // justify-content: space-around;
        // flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        padding: 20px 0 0 0;
      }
      .carousel-item {
        margin: 20px 8px 20px;
        padding: 0px 15px 30px;
        min-width: 200px !important;
        width: 35% !important;
        height: 300px !important;
        display: flex;
        flex-direction: column;
        align-items: center !important;
        // justify-content: center !important;
        h1 {
          font-size: 12px;
          padding: 0 !important;
          margin: 0 auto 10px;
        }
        img {
          margin-top: -30px;
          margin-bottom: 10px;
        }
        p{
          // display: none;
        }
      }
      .carousel-button{
        position: absolute;
      }
    }
    .inner5 {
      display: grid !important;
      h3 {
        text-align: center;
        margin-bottom: 30px;
        line-height: 30px;
      }
      .div_info {
        width: 100%;
        padding: 0 20px;
      }
      .img_high_rises {
        display: none;
      }
    }
    .inner6 {
      .img_tag_desc {
        margin: 0 12px 0;
        width: auto;
        height: 150px;
        vertical-align: middle;
      }
      .tv_desc {
        line-height: 12px;
        color: #666666;
        font-size: 8px;
         &.div_top_bottom {
          max-width: 150px;
          margin: 0 auto;
        }
        &.div_left {
          padding-top: 0px;
          height: 80px;
          max-width: 185px;
          text-align: right;
        }
        &.div_right {
          padding-top: 0px;
          height: 80px;
          text-align: left;
          max-width: 185px;
        }
        .tv_title {
          margin-bottom: 4px;
          color: #FF6E51;
          font-size: 10px;
          line-height: 13.5px;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
